<template>
  <div class="container">
    <div class="row justify-content-center warp_contact_title">
      <div class="col-auto">Контакты</div>
    </div>
    <div class="row warp_contact justify-content-between">
      <div class="col-auto d-flex flex-column">
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 32 32" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3.73826 11.1376C3.36473 14.6081 3.38138 18.6107 3.90115 22.0672C4.18875 23.9797 5.74964 25.4459 7.6764 25.6134L9.69004 25.7884C13.8888 26.1534 18.1113 26.1534 22.31 25.7884L24.3236 25.6134C26.2504 25.4459 27.8113 23.9797 28.0989 22.0672C28.6186 18.6107 28.6353 14.6083 28.2618 11.1378C28.2135 10.7354 28.1592 10.3336 28.0989 9.93249C27.8113 8.01997 26.2504 6.55377 24.3236 6.38628L22.31 6.21124C18.1112 5.84625 13.8888 5.84625 9.69004 6.21124L7.6764 6.38628C5.74964 6.55377 4.18875 8.01997 3.90115 9.93249C3.84084 10.3335 3.78654 10.7353 3.73826 11.1376ZM9.86324 8.20373C13.9467 7.84876 18.0533 7.84876 22.1368 8.20373L24.1504 8.37877C25.1562 8.4662 25.971 9.23156 26.1211 10.2299C26.1367 10.3336 26.1519 10.4373 26.1666 10.5411L18.7519 14.6603C17.0405 15.6111 14.9594 15.6111 13.248 14.6603L5.8334 10.5411C5.84815 10.4373 5.86332 10.3336 5.87891 10.2299C6.02904 9.23155 6.84383 8.4662 7.8496 8.37877L9.86324 8.20373ZM26.4113 12.693C26.67 15.7167 26.5732 18.7632 26.1211 21.7698C25.971 22.7681 25.1562 23.5335 24.1504 23.6209L22.1368 23.7959C18.0533 24.1509 13.9467 24.1509 9.86325 23.7959L7.8496 23.6209C6.84383 23.5335 6.02904 22.7681 5.87891 21.7698C5.42679 18.7632 5.33005 15.7167 5.58867 12.6931L12.2767 16.4086C14.5922 17.695 17.4077 17.695 19.7232 16.4086L26.4113 12.693Z" fill="#9BBE42"/> </svg>
        <a href="mailto:post@qrm.ooo">post@qrm.ooo</a></div>
      <div class="col-auto d-flex flex-column">
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.3968 23.0361C20.3139 22.9029 20.1818 22.8078 20.0292 22.7713C19.8766 22.7349 19.7158 22.76 19.5816 22.8412L15.6602 25.2274C15.0207 25.598 14.2655 25.7157 13.5435 25.5575C12.8215 25.3992 12.1848 24.9765 11.7588 24.3724L6.4217 15.8338C6.23755 15.5408 6.11353 15.2141 6.05686 14.8727C6.00019 14.5313 6.012 14.1821 6.09161 13.8453C6.17113 13.5048 6.31756 13.1836 6.52239 12.9002C6.72721 12.6169 6.98635 12.3771 7.28471 12.1948L11.3571 9.70922C11.4242 9.66811 11.4824 9.61417 11.5285 9.55048C11.5746 9.48679 11.6076 9.41462 11.6257 9.33812C11.6439 9.26161 11.6467 9.18228 11.634 9.10469C11.6214 9.0271 11.5935 8.95277 11.552 8.88598L8.17556 3.47728C8.09259 3.34411 7.96051 3.24899 7.80791 3.21251C7.65531 3.17604 7.49449 3.20115 7.36027 3.28241L3.30374 5.76007C2.52719 6.23622 1.85653 6.86658 1.33324 7.61219C0.809947 8.3578 0.445134 9.20281 0.261349 10.095C-0.589727 13.6743 0.659048 18.5461 3.69747 23.4139C7.31653 29.2124 12.1207 32.6167 16.221 32.6167C17.442 32.6274 18.6418 32.2968 19.685 31.6622L23.5784 29.276C23.6454 29.2349 23.7037 29.1809 23.7498 29.1173C23.7959 29.0536 23.8289 28.9814 23.847 28.9049C23.8651 28.8284 23.868 28.7491 23.8553 28.6715C23.8426 28.5939 23.8148 28.5195 23.7733 28.4528L20.3968 23.0361ZM19.0645 30.6282C15.0876 33.0502 9.05845 29.7493 4.7116 22.7776C1.88396 18.2478 0.655071 13.6067 1.42263 10.3654C1.57358 9.6298 1.87336 8.93281 2.30354 8.31729C2.73372 7.70177 3.28522 7.18071 3.92416 6.78613L7.50344 4.61072L10.2476 9.00927L6.68816 11.1807C6.25538 11.4421 5.87872 11.7868 5.57995 12.1947C5.28118 12.6026 5.06622 13.0657 4.94751 13.5572C4.8288 14.0486 4.80869 14.5588 4.88834 15.0581C4.96799 15.5574 5.14582 16.036 5.41155 16.4661L10.7407 25.0127C11.3334 25.885 12.236 26.4988 13.2652 26.7293C14.2943 26.9598 15.3725 26.7897 16.2807 26.2535L19.7009 24.1656L22.445 28.5641L19.0645 30.6282Z" fill="#9BBE42"/>
          <path d="M15.8752 10.1471C16.8362 10.1949 17.7724 10.4687 18.6078 10.9462C19.4433 11.4237 20.1543 12.0914 20.6833 12.8952C21.1868 13.6548 21.5095 14.5198 21.6267 15.4236C21.7439 16.3274 21.6525 17.246 21.3594 18.109C21.3333 18.1831 21.3221 18.2617 21.3263 18.3402C21.3306 18.4187 21.3502 18.4957 21.3842 18.5666C21.4182 18.6375 21.4658 18.701 21.5243 18.7535C21.5828 18.806 21.6511 18.8465 21.7253 18.8726C21.7995 18.8987 21.878 18.9099 21.9566 18.9057C22.0351 18.9014 22.112 18.8817 22.1829 18.8478C22.2538 18.8138 22.3173 18.7662 22.3698 18.7077C22.4223 18.6491 22.4628 18.5808 22.4889 18.5067C22.8448 17.4728 22.9582 16.3708 22.8203 15.2861C22.6824 14.2013 22.2968 13.1628 21.6935 12.2509C21.0576 11.2926 20.2072 10.4958 19.2096 9.92348C18.2121 9.35119 17.0949 9.01924 15.9467 8.95396C15.7885 8.945 15.6332 8.99925 15.515 9.10478C15.3968 9.21032 15.3254 9.35849 15.3164 9.5167C15.3074 9.67492 15.3617 9.83022 15.4672 9.94843C15.5727 10.0666 15.7209 10.1381 15.8791 10.1471H15.8752Z" fill="#9BBE42"/>
          <path d="M16.9724 4.18532C16.8941 4.17618 16.8147 4.18256 16.7389 4.2041C16.663 4.22563 16.5921 4.2619 16.5302 4.31083C16.4684 4.35976 16.4168 4.4204 16.3783 4.48928C16.3399 4.55816 16.3154 4.63393 16.3063 4.71227C16.2972 4.79061 16.3035 4.86998 16.3251 4.94586C16.3466 5.02173 16.3829 5.09262 16.4318 5.15448C16.4807 5.21634 16.5414 5.26795 16.6103 5.30637C16.6791 5.3448 16.7549 5.36928 16.8332 5.37842C18.3891 5.55529 19.8863 6.07508 21.217 6.90033C22.5477 7.72557 23.6788 8.83573 24.5287 10.1508C25.363 11.4354 25.9128 12.8835 26.1412 14.3981C26.3696 15.9126 26.2714 17.4585 25.8531 18.932C25.8261 19.0088 25.815 19.0903 25.8207 19.1715C25.8264 19.2527 25.8486 19.3318 25.886 19.4041C25.9234 19.4764 25.9753 19.5403 26.0383 19.5918C26.1013 19.6433 26.1742 19.6814 26.2525 19.7036C26.3307 19.7259 26.4128 19.732 26.4935 19.7214C26.5742 19.7108 26.6518 19.6838 26.7217 19.6421C26.7916 19.6003 26.8522 19.5447 26.8998 19.4787C26.9474 19.4127 26.9809 19.3376 26.9984 19.2581C27.463 17.6229 27.5727 15.9072 27.3203 14.2261C27.0679 12.545 26.4592 10.9372 25.5349 9.51051C24.5908 8.04448 23.333 6.80634 21.8522 5.88545C20.3715 4.96455 18.7048 4.38388 16.9724 4.18532Z" fill="#9BBE42"/>
          <path d="M28.983 7.21185C27.7086 5.26584 26.0317 3.61566 24.0656 2.37255C22.0994 1.12945 19.8895 0.322312 17.585 0.00554773C17.4268 -0.0160749 17.2665 0.0260385 17.1393 0.122624C17.0121 0.219209 16.9285 0.362353 16.9069 0.520568C16.8853 0.678782 16.9274 0.839106 17.024 0.96627C17.1206 1.09343 17.2637 1.17702 17.4219 1.19864C19.5566 1.49041 21.604 2.23631 23.4261 3.3861C25.2482 4.53588 26.8026 6.06283 27.9848 7.86408C29.1391 9.62629 29.907 11.613 30.2381 13.6934C30.5692 15.7738 30.4559 17.9008 29.9057 19.9342C29.8852 20.01 29.8799 20.089 29.8901 20.1668C29.9002 20.2446 29.9256 20.3196 29.9647 20.3876C30.0039 20.4555 30.056 20.5151 30.1183 20.5629C30.1805 20.6107 30.2515 20.6457 30.3273 20.666C30.3779 20.6793 30.43 20.686 30.4824 20.6859C30.6137 20.686 30.7414 20.6428 30.8456 20.563C30.9499 20.4832 31.0249 20.3712 31.059 20.2444C31.6523 18.0486 31.7741 15.7521 31.4163 13.5059C31.0585 11.2597 30.2292 9.11463 28.983 7.21185Z" fill="#9BBE42"/>
        </svg>
        <a href="tel:+74951060775">+7 (495) 106-07-75</a></div>
      <div class="col-auto d-flex flex-column">
        <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 16.0571L13.5116 19.6L27.4189 8.52857L17.6081 21.8143L31.5465 32L35 1L2 16.0571Z" stroke="#9BBE42"/>
        </svg>

        <a href="https://t.me/QRManager_bot">@QRManager_bot</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>