<template>
  <header>
    <div class="container">
      <div class="row justify-content-between align-content-center">
        <div class="col-auto">
          <div class="d-flex align-content-center cursor-p" @click="refrash">
            <svg width="249" height="28" viewBox="0 0 249 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M40.4 16.6998C41.7 16.6998 42.7 16.2998 43.4 15.5998C44.1 14.8998 44.5 13.7998 44.5 12.5998C44.5 11.2998 44.1 10.2998 43.4 9.59982C42.7 8.89982 41.7 8.49982 40.4 8.49982C39.1 8.49982 38.1 8.89982 37.4 9.59982C36.7 10.2998 36.3 11.2998 36.3 12.5998C36.3 13.8998 36.7 14.8998 37.4 15.5998C38.1 16.3998 39.1 16.6998 40.4 16.6998ZM43.9 20.3998C45.6 20.1998 48.1 20.3998 51.2 20.6998V24.1998C49.2 23.8998 47.3 23.7998 45.6 23.7998C43.9 23.7998 42.4 23.7998 41.1 23.8998C39.8 23.9998 38.2 24.1998 36.2 24.4998L35.9 21.1998C36 21.1998 36.2 21.1998 36.7 21.0998C37.2 21.0998 37.5 20.9998 37.6 20.9998C37.8 20.9998 38.1 20.9998 38.5 20.8998C38.9 20.8998 39.3 20.7998 39.5 20.7998C39.8 20.7998 40.1 20.6998 40.5 20.5998C40.9 20.4998 41.2 20.3998 41.5 20.2998C41.8 20.1998 42.1 20.0998 42.4 19.8998C42.8 19.7998 43.1 19.5998 43.4 19.4998L42.8 18.5998C42.3 18.9998 41.8 19.3998 41.2 19.5998C40.6 19.7998 39.8 19.8998 38.7 19.8998C36.9 19.8998 35.3 19.1998 34 17.8998C32.7 16.4998 32 14.7998 32 12.4998C32 10.2998 32.7 8.49982 34.2 6.99982C35.7 5.49982 37.7 4.7998 40 4.7998C42.4 4.7998 44.4 5.49981 45.9 6.89981C47.4 8.29981 48.1 10.0998 48.1 12.3998C48.5 15.9998 47 18.5998 43.9 20.3998Z" fill="#9BBE42"/>
              <path d="M54.1 11.6002H56.1C57.3 11.6002 58 11.6002 58.3 11.5002C58.8 11.3002 59 10.9002 59 10.2002C59 10.0002 59 9.80019 58.9 9.60019C58.9 9.40019 58.8 9.3002 58.6 9.2002C58.5 9.1002 58.4 9.00018 58.2 9.00018C58.1 8.90018 57.9 8.90018 57.7 8.90018C57.4 8.90018 57.2 8.90018 57.1 8.90018C56.9 8.90018 56.7 8.90018 56.3 8.90018C56.2 8.90018 56.1 8.90018 56 8.90018H54V11.6002H54.1ZM50 20.0002V5.2002H56C60.6 5.2002 62.9 6.60017 62.9 9.30017C62.9 9.90017 62.8 10.4002 62.5 10.8002C62.3 11.2002 61.9 11.5002 61.5 11.8002C61.1 12.0002 60.6 12.2002 60.2 12.3002C59.8 12.4002 59.3 12.5002 58.8 12.5002V13.5002H61.1C61.7 13.5002 62.2 13.7002 62.6 14.0002C63 14.4002 63.1 14.9002 63.1 15.5002V19.9002H59.1V15.0002H54V19.9002H50V20.0002Z" fill="#9BBE42"/>
              <path d="M78.6004 15.8C78.6004 17.2 78.1004 18.3 77.0004 19.1C75.9004 19.9 74.3004 20.3 72.0004 20.3C69.4004 20.3 67.4004 19.8 66.2004 18.8C65.0004 17.8 64.4004 16.3 64.4004 14.3H68.4004C68.4004 15.2 68.7004 15.8 69.2004 16.2C69.7004 16.6 70.7004 16.7 72.1004 16.7C73.1004 16.7 73.7004 16.6 74.0004 16.5C74.3004 16.4 74.5004 16.2 74.5004 15.9C74.5004 15.6 74.3004 15.3 73.8004 15.1C73.4004 14.9 72.6004 14.6 71.5004 14.2C71.2004 14.1 71.0004 14 70.9004 14C69.0004 13.3 67.7004 12.8 67.0004 12.4C65.7004 11.6 65.0004 10.4 65.0004 9C65.0004 7.7 65.5004 6.7 66.6004 6C67.6004 5.3 69.1004 5 71.1004 5C73.3004 5 75.0004 5.49999 76.2004 6.39999C77.4004 7.39999 77.9004 8.59999 77.9004 10.3H73.9004C73.9004 9.59999 73.7004 9.09999 73.2004 8.89999C72.7004 8.59999 72.0004 8.5 71.1004 8.5C69.7004 8.5 69.1004 8.70001 69.1004 9.20001C69.1004 9.40001 69.2004 9.60001 69.4004 9.70001C69.6004 9.80001 70.3004 10.1 71.4004 10.5C71.5004 10.5 71.8004 10.6 72.2004 10.8C72.6004 10.9 72.9004 11 73.1004 11.1C73.3004 11.2 73.6004 11.3 74.0004 11.5C74.4004 11.7 74.7004 11.8 75.0004 11.9C75.2004 12 75.5004 12.2 75.9004 12.4C76.3004 12.6 76.5004 12.8 76.7004 12.9C76.9004 13.1 77.1004 13.2 77.4004 13.5C77.7004 13.7 77.8004 14 78.0004 14.2C78.1004 14.4 78.3004 14.7 78.3004 15C78.5004 15.2 78.6004 15.5 78.6004 15.8Z" fill="#9BBE42"/>
              <path d="M82.9998 13.6001H87.1998C87.1998 12.8001 86.4998 12.3001 85.0998 12.3001C84.2998 12.3001 83.7998 12.4001 83.4998 12.6001C83.1998 12.8001 82.9998 13.2001 82.9998 13.6001ZM79.2998 14.5001C79.2998 12.8001 79.7998 11.5001 80.8998 10.6001C81.9998 9.6001 83.3998 9.1001 85.0998 9.1001C86.7998 9.1001 88.1998 9.60009 89.2998 10.5001C90.3998 11.5001 90.9998 12.7001 90.9998 14.2001V15.2001H82.9998C82.9998 15.7001 83.1998 16.2001 83.5998 16.5001C83.9998 16.8001 84.6998 17.0001 85.4998 17.0001C85.9998 17.0001 86.3998 16.9001 86.6998 16.7001C87.0998 16.5001 87.2998 16.3001 87.3998 16.0001H91.3998C91.3998 16.4001 91.1998 16.8001 90.9998 17.3001C90.6998 17.7001 90.3998 18.2001 89.8998 18.6001C89.3998 19.0001 88.7998 19.3001 88.0998 19.6001C87.2998 19.9001 86.4998 20.0001 85.5998 20.0001C83.5998 20.0001 82.0998 19.5001 80.9998 18.4001C79.7998 17.5001 79.2998 16.2001 79.2998 14.5001Z" fill="#9BBE42"/>
              <path d="M92.4004 20.0002V11.1002C92.4004 10.5002 92.6004 10.0002 92.9004 9.7002C93.3004 9.4002 93.7004 9.2002 94.3004 9.2002L101.7 9.40018V12.9002L96.3004 12.8002V20.0002H92.4004Z" fill="#9BBE42"/>
              <path d="M111.1 9.2998H115.1L112.7 19.9998H104.4L102 9.2998H106L108.1 18.9998H109L111.1 9.2998Z" fill="#9BBE42"/>
              <path d="M115.8 20V16.5H118.7V12.8L116 12.9V9.39999L120.7 9.20001C121.3 9.20001 121.8 9.30001 122.2 9.70001C122.6 10 122.7 10.6 122.7 11.2V16.6H125.7V20.1H115.8V20ZM120.5 8.39999C119.1 8.39999 118.5 7.80001 118.5 6.70001C118.5 5.60001 119.2 5 120.5 5C121.2 5 121.7 5.09999 122.1 5.39999C122.5 5.69999 122.7 6.10001 122.7 6.70001C122.6 7.90001 121.9 8.39999 120.5 8.39999Z" fill="#9BBE42"/>
              <path d="M126.1 14.6001C126.1 12.8001 126.7 11.4001 127.8 10.5001C128.9 9.50009 130.3 9.1001 132.1 9.1001C133.8 9.1001 135.2 9.50009 136.3 10.4001C137.4 11.3001 138.1 12.4001 138.1 13.9001H134.1C134.1 13.6001 133.9 13.3001 133.5 13.1001C133.2 12.8001 132.7 12.7001 132.1 12.7001C131.5 12.7001 131.1 12.9001 130.7 13.2001C130.3 13.5001 130.1 14.0001 130.1 14.7001C130.1 15.4001 130.3 15.9001 130.7 16.2001C131.1 16.5001 131.6 16.7001 132.1 16.7001C132.7 16.7001 133.2 16.6001 133.5 16.3001C133.9 16.0001 134 15.7001 134.1 15.4001H138.1C138 16.9001 137.4 18.0001 136.3 18.9001C135.2 19.8001 133.8 20.2001 132.1 20.2001C130.3 20.2001 128.9 19.7001 127.8 18.8001C126.7 17.8001 126.1 16.5001 126.1 14.6001Z" fill="#9BBE42"/>
              <path d="M142.5 13.6001H146.7C146.7 12.8001 146 12.3001 144.6 12.3001C143.8 12.3001 143.3 12.4001 143 12.6001C142.7 12.8001 142.5 13.2001 142.5 13.6001ZM138.8 14.5001C138.8 12.8001 139.3 11.5001 140.4 10.6001C141.5 9.6001 142.9 9.1001 144.6 9.1001C146.3 9.1001 147.7 9.60009 148.8 10.5001C149.9 11.5001 150.5 12.7001 150.5 14.2001V15.2001H142.5C142.5 15.7001 142.7 16.2001 143.1 16.5001C143.5 16.8001 144.2 17.0001 145 17.0001C145.5 17.0001 145.9 16.9001 146.2 16.7001C146.6 16.5001 146.8 16.3001 146.9 16.0001H150.9C150.9 16.4001 150.7 16.8001 150.5 17.3001C150.2 17.7001 149.9 18.2001 149.4 18.6001C148.9 19.0001 148.3 19.3001 147.6 19.6001C146.8 19.9001 146 20.0001 145.1 20.0001C143.1 20.0001 141.6 19.5001 140.5 18.4001C139.3 17.5001 138.8 16.2001 138.8 14.5001Z" fill="#9BBE42"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V28H28V0H0ZM24 4H4V24H24V4Z" fill="#9BBE42"/>
              <path d="M19 9H9V19H19V9Z" fill="#9BBE42"/>
              <path d="M246.5 0H192.5C191.1 0 190 1.1 190 2.5V25.4C190 26.8 191.1 27.9 192.5 27.9H246.5C247.9 27.9 249 26.8 249 25.4V2.5C249 1.1 247.9 0 246.5 0Z" fill="white"/>
              <path d="M208.1 12.7002C208.4 12.7002 208.7 12.7002 209 12.6002C209.3 12.5002 209.5 12.5002 209.8 12.3002C210 12.2002 210.2 12.0002 210.3 11.7002C210.4 11.5002 210.5 11.2002 210.5 10.8002C210.5 10.4002 210.4 10.0002 210.1 9.80017C209.8 9.50017 209.4 9.40018 208.8 9.40018H205.7L205 12.6002H208.1V12.7002ZM203.8 18.5002H207.2C208 18.5002 208.7 18.3002 209.2 18.0002C209.7 17.7002 209.9 17.1002 209.9 16.4002C209.9 15.8002 209.7 15.4002 209.3 15.1002C208.9 14.8002 208.4 14.7002 207.8 14.7002H204.5L203.8 18.5002ZM203.2 7.2002H209.2C209.8 7.2002 210.4 7.30018 210.9 7.40018C211.4 7.50018 211.9 7.70018 212.3 8.00018C212.7 8.30018 212.9 8.60018 213.2 9.00018C213.4 9.40018 213.5 9.90018 213.5 10.4002C213.5 11.2002 213.3 11.9002 212.8 12.4002C212.3 12.9002 211.6 13.3002 210.8 13.6002V13.7002C212.3 14.1002 213.1 15.0002 213.1 16.5002C213.1 17.6002 212.6 18.5002 211.7 19.3002C211.2 19.7002 210.6 20.1002 209.9 20.3002C209.2 20.5002 208.3 20.7002 207.3 20.7002H200.4L203.2 7.2002Z" fill="#1C00A1"/>
              <path d="M219.9 20.9999C219 20.9999 218.2 20.8999 217.5 20.6999C216.7 20.4999 216.1 20.2999 215.5 19.8999C215 19.4999 214.5 19.0999 214.2 18.4999C213.9 17.8999 213.8 17.2999 213.8 16.4999H216.7C216.7 16.8999 216.7 17.2999 216.9 17.5999C217.1 17.8999 217.3 18.0999 217.6 18.2999C217.9 18.4999 218.2 18.5999 218.6 18.6999C219 18.7999 219.4 18.7999 219.9 18.7999C220.3 18.7999 220.6 18.7999 221 18.6999C221.3 18.5999 221.6 18.4999 221.9 18.3999C222.2 18.2999 222.4 18.0999 222.5 17.8999C222.7 17.6999 222.7 17.3999 222.7 17.0999C222.7 16.8999 222.7 16.6999 222.6 16.4999C222.5 16.2999 222.3 16.1999 222.1 15.9999C221.8 15.7999 221.5 15.6999 221.1 15.4999C220.7 15.2999 220.2 15.0999 219.5 14.8999C218.8 14.6999 218.2 14.4999 217.7 14.1999C217.2 13.9999 216.7 13.6999 216.4 13.3999C216 13.0999 215.8 12.7999 215.6 12.3999C215.4 11.9999 215.3 11.5999 215.3 10.9999C215.3 10.3999 215.4 9.7999 215.7 9.2999C216 8.7999 216.3 8.39991 216.8 7.99991C217.3 7.59991 217.9 7.39992 218.5 7.19992C219.2 6.99992 219.9 6.8999 220.7 6.8999C221.5 6.8999 222.2 6.99991 222.9 7.09991C223.6 7.29991 224.2 7.4999 224.7 7.7999C225.2 8.0999 225.6 8.59991 225.9 9.09991C226.2 9.59991 226.3 10.2999 226.3 10.9999H223.4C223.4 10.2999 223.2 9.79991 222.7 9.49991C222.2 9.09991 221.5 8.99991 220.6 8.99991C220.3 8.99991 220 8.99991 219.7 9.09991C219.4 9.19991 219.1 9.1999 218.9 9.3999C218.7 9.4999 218.5 9.6999 218.3 9.8999C218.1 10.0999 218.1 10.3999 218.1 10.6999C218.1 10.8999 218.1 11.0999 218.2 11.2999C218.3 11.4999 218.5 11.5999 218.7 11.6999C218.9 11.7999 219.2 11.9999 219.6 12.0999C220 12.1999 220.5 12.3999 221.1 12.5999C221.8 12.7999 222.5 13.0999 223.1 13.2999C223.7 13.5999 224.1 13.7999 224.5 14.1999C224.9 14.4999 225.2 14.8999 225.4 15.2999C225.6 15.6999 225.7 16.1999 225.7 16.6999C225.7 17.3999 225.6 17.9999 225.3 18.5999C225 19.0999 224.6 19.5999 224.1 19.8999C223.6 20.2999 223 20.4999 222.2 20.6999C221.5 20.8999 220.7 20.9999 219.9 20.9999Z" fill="#1C00A1"/>
              <path d="M232.6 20.9999C231.7 20.9999 230.9 20.8999 230.2 20.6999C229.4 20.4999 228.8 20.2999 228.2 19.8999C227.7 19.4999 227.2 19.0999 226.9 18.4999C226.6 17.8999 226.5 17.2999 226.5 16.4999H229.4C229.4 16.8999 229.4 17.2999 229.6 17.5999C229.8 17.8999 230 18.0999 230.3 18.2999C230.6 18.4999 230.9 18.5999 231.3 18.6999C231.7 18.7999 232.1 18.7999 232.6 18.7999C233 18.7999 233.3 18.7999 233.7 18.6999C234 18.5999 234.3 18.4999 234.6 18.3999C234.9 18.2999 235.1 18.0999 235.2 17.8999C235.4 17.6999 235.4 17.3999 235.4 17.0999C235.4 16.8999 235.4 16.6999 235.3 16.4999C235.2 16.2999 235 16.1999 234.8 15.9999C234.5 15.7999 234.2 15.6999 233.8 15.4999C233.4 15.2999 232.9 15.0999 232.2 14.8999C231.5 14.6999 230.9 14.4999 230.4 14.1999C229.9 13.9999 229.4 13.6999 229.1 13.3999C228.7 13.0999 228.5 12.7999 228.3 12.3999C228.1 11.9999 228 11.5999 228 10.9999C228 10.3999 228.1 9.7999 228.4 9.2999C228.7 8.7999 229 8.39991 229.5 7.99991C230 7.59991 230.6 7.39992 231.2 7.19992C231.9 6.99992 232.6 6.8999 233.4 6.8999C234.2 6.8999 234.9 6.99991 235.6 7.09991C236.3 7.29991 236.9 7.4999 237.4 7.7999C237.9 8.0999 238.3 8.59991 238.6 9.09991C238.9 9.59991 239 10.2999 239 10.9999H236.1C236.1 10.2999 235.9 9.79991 235.4 9.49991C234.9 9.09991 234.2 8.99991 233.3 8.99991C233 8.99991 232.7 8.99991 232.4 9.09991C232.1 9.19991 231.8 9.1999 231.6 9.3999C231.4 9.4999 231.2 9.6999 231 9.8999C230.8 10.0999 230.8 10.3999 230.8 10.6999C230.8 10.8999 230.8 11.0999 230.9 11.2999C231 11.4999 231.2 11.5999 231.4 11.6999C231.6 11.7999 231.9 11.9999 232.3 12.0999C232.7 12.1999 233.2 12.3999 233.8 12.5999C234.5 12.7999 235.2 13.0999 235.8 13.2999C236.4 13.5999 236.8 13.7999 237.2 14.1999C237.6 14.4999 237.9 14.8999 238.1 15.2999C238.3 15.6999 238.4 16.1999 238.4 16.6999C238.4 17.3999 238.3 17.9999 238 18.5999C237.7 19.0999 237.3 19.5999 236.8 19.8999C236.3 20.2999 235.7 20.4999 234.9 20.6999C234.2 20.8999 233.4 20.9999 232.6 20.9999Z" fill="#1C00A1"/>
              <path d="M178.3 10.9998H180.2L176.3 19.7998V22.3998C176.3 23.1998 175.9 23.5998 175 23.5998L171.3 23.3998V21.8998L174.5 21.9998V19.7998L170.6 10.9998H172.5L175.2 17.5998V18.3998H175.7V17.5998L178.3 10.9998ZM162 15.4998C162 16.5998 162.3 17.2998 162.9 17.8998C163.5 18.3998 164.3 18.6998 165.2 18.6998C166.1 18.6998 166.9 18.3998 167.4 17.8998C168 17.3998 168.2 16.5998 168.2 15.4998C168.2 14.3998 167.9 13.6998 167.4 13.0998C166.9 12.4998 166.1 12.2998 165.2 12.2998C164.2 12.2998 163.5 12.5998 162.9 13.0998C162.3 13.6998 162 14.4998 162 15.4998ZM160.3 19.9998V6.7998H162V12.1998L161.7 13.2998H162.2C162.4 12.5998 162.8 11.9998 163.4 11.4998C164 10.9998 164.8 10.7998 165.8 10.7998C167.1 10.7998 168.1 11.1998 168.8 12.0998C169.6 12.9998 170 14.0998 170 15.3998C170 16.7998 169.6 17.8998 168.8 18.7998C168 19.6998 167 20.0998 165.8 20.0998C164.8 20.0998 164 19.8998 163.4 19.3998C162.8 18.8998 162.4 18.2998 162.2 17.5998H161.7L162 18.7998V19.9998H160.3Z" fill="#8E8E8E"/>
            </svg>
          </div>
        </div>
        <div class="col-auto warp_phone d-flex align-items-center"><a href="tel:+74951060775">+7 (495) 106-07-75</a></div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  methods:{
    refrash(){
      window.location.reload();
    }
  }
}
</script>

